<template>
    <Transition>
        <div
            v-if="modelValue"
            v-click-outside="onClose"
            :class="classes"
        >
            <div class="notification__content">
                <slot />
            </div>
            <button
                v-if="manual"
                class="notification__close-button"
                type="button"
                @click="onClose"
            >
                <fa-icon
                    class="h-[20px] w-[20px]"
                    color="#616266"
                    :icon="['fas', 'times']"
                />
            </button>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { computed, toRef, watch } from 'vue';

export type NotificationType = 'info' | 'warning' | 'danger' | 'success';

interface Props {
    modelValue: boolean;
    type?: NotificationType;
    fixed?: boolean;
    manual?: boolean;
    width?: string;
    closeDelay?: number;
    center?: boolean;
    secondary?: boolean;
    autoClose?: boolean;
}

interface Emits {
    (e: 'update:model-value', value: boolean): void;
    (e: 'close'): void;
}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {
    type: 'info',
    width: '500px',
    closeDelay: 3000,
    autoClose: true,
});

const width = toRef(props, 'width', '500px');

const classes = computed(() => [
    'notification',
    `notification--${props.type}`,
    {
        'notification--fixed': props.fixed,
        'notification--manual': props.manual,
        'notification--center': props.center,
        'notification--secondary': props.secondary,
    },
]);

function onClose() {
    emit('close');
    emit('update:model-value', false);
}

watch(
    () => props.modelValue,
    (value) => {
        let timeout!: NodeJS.Timeout;

        if (props.manual || !props.autoClose || !value) {
            clearInterval(timeout);
            return;
        }

        timeout = setTimeout(onClose, props.closeDelay);
    },
    { immediate: true },
);
</script>

<style scoped lang="scss">
$padding: 15px;
$indent: 10px;

@media screen and (min-width: 1000px) {
    $padding: 25px;
    $indent: 20px;
}

.notification {
    width: v-bind(width);
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
