import api, { handleApiError } from '@/api';
import { ApiResponse } from '../types';
import {
    CreateMeetingPayload,
    CreateMeetingResponse,
    Meeting,
    MeetingSession,
    MeetingUser,
    PaginatedMeetings,
    UpdateMeetingPayload,
} from './types';

export const getMeetingById = async (id: string): ApiResponse<Meeting> => {
    try {
        const { data } = await api.get<Meeting>('meetings', {
            params: { id },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getMeetingsByCreatorId = async (
    id: number,
): ApiResponse<Meeting[]> => {
    try {
        const { data } = await api.get<Meeting[]>('meetings/my-meetings', {
            params: { id },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getMeetingMembers = async (
    id: string,
): ApiResponse<MeetingUser[]> => {
    try {
        const { data } = await api.get<MeetingUser[]>('meetings/members', {
            params: { id },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getPaginatedMeetings = async (
    page = 1,
    size = 10,
): ApiResponse<PaginatedMeetings> => {
    try {
        const { data } = await api.get<PaginatedMeetings>(
            'meetings/paginated',
            { params: { page, size } },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const createMeeting = async (
    payload: CreateMeetingPayload,
): ApiResponse<CreateMeetingResponse> => {
    try {
        const { data } = await api.post<CreateMeetingResponse>(
            'meetings',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const updateMeeting = async (
    payload: UpdateMeetingPayload,
): ApiResponse<Meeting> => {
    try {
        const { data } = await api.patch<Meeting>('meetings', payload);

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const deleteMeeting = async (id: string): ApiResponse => {
    try {
        const { data } = await api.delete('meetings', {
            params: { id },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getMeetingTranscript = async (
    meetingId: string,
    format: 'pdf' | 'txt' = 'txt',
): ApiResponse<Blob> => {
    try {
        const { data } = await api.get<Blob>('meeting-protocols', {
            params: { meetingId, format },
            responseType: 'blob',
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const createMeetingProtocol = async (
    meetingId: string,
    format: 'pdf' | 'txt' = 'txt',
): ApiResponse => {
    try {
        const { data } = await api.post(
            'meeting-protocols/summarization',
            null,
            { params: { meetingId, format } },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getMeetingProtocol = async (
    meetingId: string,
): ApiResponse<string | null> => {
    try {
        const { data } = await api.get<string | null>(
            'meeting-protocols/summarization',
            { params: { meetingId } },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getMeetingSessions = async (
    meetingId: string,
): ApiResponse<MeetingSession[]> => {
    try {
        const { data } = await api.get<MeetingSession[]>('meetings/sessions', {
            params: { meetingId },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};
