<template>
    <VApp>
        <VMain>
            <RouterView />
        </VMain>

        <AppNotifications />

        <ModalsContainer />

        <LoadingScreen />
    </VApp>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import { ModalsContainer } from 'vue-final-modal';
import AppNotifications from '@/blocks/AppNotifications.vue';

const LoadingScreen = defineAsyncComponent(
    () => import('@/blocks/LoadingScreen.vue'),
);
</script>

<style lang="scss">
@import './assets/styles/styles.scss';
</style>
