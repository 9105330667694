import { string } from 'yup';
import requiredRule from './required';

export const getPasswordRules = () => ({
    passwordLengthRule: string().min(6).max(16),
    passwordDigitRule: string().matches(/^(?=.*\d).*$/),
    passwordCharactersRule: string().matches(/^[A-Za-z0-9?!_]+$/),
    passwordSpecialCharacterRule: string().matches(/^(?=.*[?!_]).*$/),
    passwordLetterRule: string()
        .matches(/^(?=.*[a-z]).*$/)
        .matches(/^(?=.*[A-Z]).*$/),
});

export default (required = true) => {
    const {
        passwordLengthRule,
        passwordCharactersRule,
        passwordSpecialCharacterRule,
    } = getPasswordRules();

    return requiredRule(required)
        .concat(passwordLengthRule)
        .concat(passwordCharactersRule)
        .concat(passwordSpecialCharacterRule);
};
