export type GridLayoutDefinition = {
    columns: number | string;
    rows: number | string;
    maxTiles: number;
};

export enum GridLayoutType {
    default = 'default',
    secondary = 'secondary',
}

export const GRID_LAYOUTS_XS: GridLayoutDefinition[] = [
    {
        columns: 1,
        rows: 1,
        maxTiles: 2,
    },
    {
        columns: 1,
        rows: 3,
        maxTiles: 3,
    },
];

export const GRID_LAYOUTS_SM: GridLayoutDefinition[] = [
    {
        columns: 1,
        rows: 1,
        maxTiles: 2,
    },
    {
        columns: 1,
        rows: 3,
        maxTiles: 3,
    },
    {
        columns: 2,
        rows: 3,
        maxTiles: 6,
    },
    {
        columns: 2,
        rows: 4,
        maxTiles: 8,
    },
];

export const GRID_LAYOUTS_MD: GridLayoutDefinition[] = [
    {
        columns: 1,
        rows: 1,
        maxTiles: 2,
    },
    {
        columns: 2,
        rows: 2,
        maxTiles: 4,
    },
    {
        columns: 2,
        rows: 3,
        maxTiles: 6,
    },
    {
        columns: 3,
        rows: 3,
        maxTiles: 9,
    },
    {
        columns: 4,
        rows: 3,
        maxTiles: 12,
    },
    {
        columns: 4,
        rows: 4,
        maxTiles: 16,
    },
];

export const GRID_LAYOUTS_LG: GridLayoutDefinition[] = [
    {
        columns: 1,
        rows: 1,
        maxTiles: 2,
    },
    {
        columns: 2,
        rows: 2,
        maxTiles: 4,
    },
    {
        columns: 4,
        rows: 2,
        maxTiles: 8,
    },
    {
        columns: 4,
        rows: 3,
        maxTiles: 10,
    },
    {
        columns: 5,
        rows: 3,
        maxTiles: 15,
    },
];

export const GRID_LAYOUTS_XL: GridLayoutDefinition[] = [
    {
        columns: 1,
        rows: 1,
        maxTiles: 2,
    },
    {
        columns: 2,
        rows: 2,
        maxTiles: 4,
    },
    {
        columns: 3,
        rows: 2,
        maxTiles: 6,
    },
    {
        columns: 3,
        rows: 3,
        maxTiles: 9,
    },
    {
        columns: 4,
        rows: 3,
        maxTiles: 12,
    },
    {
        columns: 5,
        rows: 3,
        maxTiles: 15,
    },
    {
        columns: 5,
        rows: 4,
        maxTiles: 20,
    },
    {
        columns: 6,
        rows: 4,
        maxTiles: 24,
    },
];

function getLayoutsBySize(
    width: number,
    height: number,
): Readonly<GridLayoutDefinition[]> {
    let layouts = GRID_LAYOUTS_XS;

    if (width >= 1300 && height >= 800) {
        layouts = GRID_LAYOUTS_XL;
    } else if (width >= 1000 && height >= 600) {
        layouts = GRID_LAYOUTS_LG;
    } else if (width >= 700 && height >= 600) {
        layouts = GRID_LAYOUTS_MD;
    } else if (width >= 390 && height >= 500) {
        layouts = GRID_LAYOUTS_SM;
    }

    const isReversed =
        (layouts === GRID_LAYOUTS_XS || layouts === GRID_LAYOUTS_SM) &&
        height < width;

    if (!isReversed) return layouts;

    return layouts.map((layout) => ({
        ...layout,
        columns: layout.rows,
        rows: layout.columns,
    }));
}

function setDefaultGridLayout(
    participantCount: number,
    width: number,
    height: number,
): Readonly<GridLayoutDefinition> {
    const layouts = getLayoutsBySize(width, height);

    return (
        layouts.find(({ maxTiles }) => maxTiles >= participantCount) ??
        layouts[layouts.length - 1]
    );
}

function setSpeakerGridLayout(width: number): Readonly<GridLayoutDefinition> {
    const COLUMN_SIZE = 250;
    const GAP = 10;
    const maxTiles = Math.max(1, Math.floor(width / (COLUMN_SIZE + GAP * 1.5)));

    return {
        columns: 'auto-fit',
        rows: 1,
        maxTiles,
    };
}

export function selectGridLayout(
    participantCount: number,
    width: number,
    height: number,
    type: GridLayoutType = GridLayoutType.default,
): Readonly<GridLayoutDefinition> {
    if (type === GridLayoutType.default) {
        return setDefaultGridLayout(participantCount, width, height);
    }

    return setSpeakerGridLayout(width);
}
