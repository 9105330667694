import { createLogger } from '@/utils';
import { ClientData, SocketClientCallbacks, SocketClientEvent } from './types';

interface NestJson {
    event: SocketClientEvent;
    data: any;
}

const logger = createLogger('WebSocket');

export class SocketClient {
    private uri: string;
    private websocket: WebSocket;
    private events: Map<
        SocketClientEvent,
        SocketClientCallbacks[SocketClientEvent]
    > = new Map();
    private pingTimer?: NodeJS.Timeout;
    private reconnectTimer?: NodeJS.Timeout;
    private reconnectionCount: number = 0;
    private reconnectionDelay: number = 1000;
    private minReconnectionDelay: number = 1000;
    private maxReconnectionDelay: number = 10000;
    private reconnectionDelayGrowFactor: number = 1.3;
    private bufferedData: string[] = [];

    private clientData: ClientData = {
        id: null,
        meetingId: null,
        streamId: null,
    };

    public constructor(uri: string, immediate = true) {
        this.uri = uri;

        if (immediate) {
            this.connect();
        }

        window.addEventListener('online', this.onlineListener.bind(this));
    }

    public get isOpen(): boolean {
        return this.websocket.readyState === this.websocket.OPEN;
    }

    private sendBuffer() {
        if (this.bufferedData.length && this.isOpen) {
            for (const buffer of this.bufferedData) {
                this.websocket.send(buffer);
            }

            this.bufferedData = [];
        }
    }

    public emit<T extends SocketClientEvent>(
        event: T,
        payload?: Parameters<SocketClientCallbacks[T]>[0],
    ) {
        const jsonRpc: NestJson = { event, data: payload };
        const data = JSON.stringify(jsonRpc);

        if (!this.isOpen) {
            this.bufferedData.push(data);
            return;
        }

        this.sendBuffer();
        this.websocket.send(data);
    }

    public onConnect(handler?: () => void) {
        this.websocket.addEventListener('open', () => {
            this.reconnectionCount = 0;
            this.reconnectionDelay = this.minReconnectionDelay;
            this.initPingTimer();
            this.on('onError', (error) => logger.error(error));

            handler?.();
        });
    }

    public setClientData(data: ClientData) {
        this.clientData = data;
    }

    public on<T extends SocketClientEvent>(
        event: T,
        handler: SocketClientCallbacks[T],
    ) {
        this.events.set(event, handler);
        return this;
    }

    public off(event: SocketClientEvent) {
        this.events.delete(event);
    }

    public removeAllListeners() {
        this.events.clear();
    }

    public hasListeners(key: SocketClientEvent) {
        return this.events.has(key);
    }

    public connect() {
        this.websocket = new WebSocket(this.uri);
        this.initializeMessageListener();

        return new Promise((resolve) =>
            this.onConnect(() => resolve(this.websocket)),
        );
    }

    private reconnect() {
        this.reconnectionCount++;

        const delay =
            this.minReconnectionDelay *
            Math.pow(this.reconnectionDelayGrowFactor, this.reconnectionCount);
        this.reconnectionDelay = Math.min(delay, this.maxReconnectionDelay);

        clearTimeout(this.reconnectTimer);

        this.reconnectTimer = setTimeout(() => {
            if (this.isOpen) return;

            this.connect();
            this.onConnect(this.onReconnect);
        }, this.reconnectionDelay);
    }

    public disconnect() {
        this.websocket.close();
        this.clearPingTimer();
        window.removeEventListener('online', this.onlineListener);
    }

    private initializeMessageListener() {
        this.websocket.addEventListener('message', (event) =>
            this.handleMessage(event.data.toString()),
        );
        this.websocket.addEventListener('close', (event) => {
            this.clearPingTimer();
            logger.log('close | Reason', event.reason);

            if (!event.wasClean) this.reconnect();
        });
        this.websocket.addEventListener('error', (event) => {
            this.clearPingTimer();
            logger.error('Error', event);
        });
    }

    private handleMessage(message: string) {
        const jsonRpc: NestJson = JSON.parse(message);
        const subscribedHandler = this.events.get(jsonRpc.event);

        if (!subscribedHandler) {
            return;
        }

        subscribedHandler(jsonRpc.data);
    }

    private initPingTimer() {
        this.pingTimer = setInterval(() => {
            if (this.isOpen) this.emit('ping', this.clientData);
        }, 3000);
    }

    private clearPingTimer() {
        if (this.pingTimer) {
            clearInterval(this.pingTimer);
            this.pingTimer = undefined;
        }
    }

    private onlineListener() {
        if (this.isOpen) return;

        this.reconnectionCount = 0;
        this.reconnectionDelay = this.minReconnectionDelay;
        this.reconnect();
    }

    public setReconnectCallback(cb: () => void) {
        this.onReconnect = cb;
    }

    private onReconnect() {}
}
