import api, { handleApiError } from '@/api';
import { ApiResponse } from '../types';
import {
    LoginPayload,
    AuthResponse,
    RefreshTokenResponse,
    RegisterPayload,
    UpdatePasswordPayload,
    SendEmailConfirmationLinkPayload,
    VerifyAuthorizationCodePayload,
    Toggle2FaAuthorizationPayload,
    GenerateAndSendCodePayload,
} from './types';

export const login = async (
    payload: LoginPayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.post<AuthResponse>(
            'authorization/login',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const refreshUserTokens = async (
    token: string,
): ApiResponse<RefreshTokenResponse> => {
    try {
        const { data } = await api.patch<RefreshTokenResponse>(
            'authorization/token-refresh',
            { token },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const register = async (
    payload: RegisterPayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.post<AuthResponse>(
            'authorization/registration',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const createGuestUser = async (
    nickname: string,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.post<AuthResponse>(
            'authorization/guest-login',
            { nickname },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const sendRecoveryLink = async (email: string): ApiResponse => {
    try {
        const { data } = await api.post('authorization/send-recovery-link', {
            email,
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const updatePassword = async (
    payload: UpdatePasswordPayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.patch<AuthResponse>(
            'authorization/password-recovery',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const sendEmailConfirmationLink = async (
    payload: SendEmailConfirmationLinkPayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.post<AuthResponse>(
            'authorization/send-confirmation-new-email-link',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const verifyOldEmail = async (token: string): ApiResponse => {
    try {
        const { data } = await api.post(
            'authorization/verify-change-email',
            undefined,
            { params: { token } },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const verifyNewEmail = async (token: string): ApiResponse => {
    try {
        const { data } = await api.post(
            'authorization/verify-new-email-and-change',
            undefined,
            { params: { token } },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const verifyAuthorizationCode = async (
    payload: VerifyAuthorizationCodePayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.patch<AuthResponse>(
            'authorization/verify',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const refreshCodeAuthorization = async (
    payload: GenerateAndSendCodePayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.patch<AuthResponse>(
            'authorization/refresh-code',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const toggle2FaAuthorization = async (
    payload: Toggle2FaAuthorizationPayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.patch<AuthResponse>(
            'authorization/toggle-2FA-state',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const generateAndSendCode = async (
    payload: GenerateAndSendCodePayload,
): ApiResponse<AuthResponse> => {
    try {
        const { data } = await api.patch<AuthResponse>(
            'authorization/send-code',
            payload,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};
