export const REQUEST_FULLSCREEN_METHODS = [
    'requestFullscreen',
    'webkitEnterFullscreen',
    'webkitRequestFullscreen',
    'mozRequestFullScreen',
    'msRequestFullscreen',
] as const;

export const EXIT_FULLSCREEN_METHODS = [
    'exitFullscreen',
    'webkitExitFullscreen',
    'mozCancelFullScreen',
    'msExitFullscreen',
] as const;

export const BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1800,
} as const;
