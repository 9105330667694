import { useLayoutStore } from './layout';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { defineAsyncComponent, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { createLogger, redirect } from '@/utils';
import { getMeetingById } from '@/api/meetings';
import { Meeting } from '@/api/meetings/types';
import { useRoomSockets } from '@/composables';
import { useAuthStore } from './auth';
import { useUserStore } from './user';
import { useJitsiApi } from '@/composables/useJitsiApi';
import { mapJitsiParticipants } from '@/utils/mappers/members';
import { JitsiMember, JitsiRoom } from '@/interfaces/jitsi';
import { LobbyStatus } from '@/interfaces/webinar';
import { MeetingUserRole } from '@/constants/roles';

export const useRoomStore = defineStore('room', () => {
    const logger = createLogger('room');

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const layoutStore = useLayoutStore();

    const { vcsSocket, analyticsSocket } = useRoomSockets();
    const { jitsiApi, localParticipantInfo, embedJitsiWidget } = useJitsiApi();

    const meetingRoom = ref<Meeting>();
    const members = ref<Record<string, JitsiMember>>({});

    const setMeetingRoom = async (meetingId: string) => {
        const response = await getMeetingById(meetingId);

        if (!response.success) {
            logger.error(response.message);
            return;
        }

        meetingRoom.value = response.data;
    };

    const enterRoom = async () => {
        let isJoined = false;

        await Promise.all([vcsSocket.connect(), analyticsSocket.connect()]);

        vcsSocket
            .on('onClientJoined', (payload) => {
                isJoined = true;

                vcsSocket.emit('UpdateStream', {
                    stream_id: localParticipantInfo.id,
                });

                vcsSocket.setReconnectCallback(async () => {
                    await authStore.refreshTokens();

                    vcsSocket.emit('Reconnect', {
                        meeting_id: meetingRoom.value?.id!,
                        token: `Bearer ${authStore.accessToken}`,
                        identity: `${userStore.profile?.id}`,
                    });
                });

                analyticsSocket.emit('JoinRoom', payload);
                analyticsSocket.setReconnectCallback(async () => {
                    await authStore.refreshTokens();

                    analyticsSocket.emit('JoinRoom', {
                        ...payload,
                        token: `Bearer ${authStore.accessToken}`,
                    });
                });
            })
            .on('onMembersUpdate', (data) => {
                const myId = localParticipantInfo.id;
                const creatorId = meetingRoom.value?.creator_id!;
                let creatorSid = '';

                data.forEach((item) => {
                    if (item.user_id === creatorId) {
                        creatorSid = item.stream_id!;
                    }

                    if (item.user_id !== userStore.userId) {
                        return;
                    }

                    if (!isJoined && item.status === LobbyStatus.approved) {
                        vcsSocket.emit('JoinRoom', {
                            meeting_id: meetingRoom.value!.id,
                            token: `Bearer ${authStore.accessToken}`,
                        });
                    }
                });

                const isCreatorModerator =
                    members.value[creatorSid]?.role ===
                    MeetingUserRole.CoOrganizer;
                const isMeModerator =
                    members.value[myId]?.role === MeetingUserRole.CoOrganizer;

                if (!isMeModerator || isCreatorModerator || !creatorSid) {
                    return;
                }

                jitsiApi.value.executeCommand('grantModerator', creatorSid);
            });

        vcsSocket.emit('JoinToLobby', {
            meeting_id: meetingRoom.value!.id,
            token: `Bearer ${authStore.accessToken}`,
        });
    };

    const handleRoomDisconnect = () => {
        layoutStore.toggleLoadingScreen(true);

        const meetingId = meetingRoom.value?.id;

        jitsiApi.value?.dispose();
        vcsSocket.emit('LeaveFromMeeting');
        vcsSocket.disconnect();
        analyticsSocket.disconnect();

        redirect({ name: 'MeetingsPage' }).then(() => {
            layoutStore.toggleLoadingScreen(false);

            if (userStore.isOrganizer || !meetingId) {
                return;
            }

            useModal({
                component: defineAsyncComponent(
                    () => import('@/blocks/RatingModal.vue'),
                ),
                defaultModelValue: true,
                keepAlive: false,
                attrs: { meetingId },
            });
        });
    };

    const updateMembers = () => {
        return jitsiApi.value
            ?.getRoomsInfo()
            .then(({ rooms }: { rooms: JitsiRoom[] }) => {
                members.value = rooms.reduce((acc, { participants }) => {
                    return { ...acc, ...mapJitsiParticipants(participants) };
                }, {});
            });
    };

    return {
        jitsiApi,
        localParticipantInfo,
        meetingRoom,
        members,
        vcsSocket,
        analyticsSocket,
        embedJitsiWidget,
        setMeetingRoom,
        updateMembers,
        enterRoom,
        handleRoomDisconnect,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRoomStore, import.meta.hot));
}
