import { reactive } from 'vue';
import { SocketClient } from '@/libs/vue-socket-plugin/client';

export function useRoomSockets() {
    const baseUrl = import.meta.env.VUE_APP_WS_HOST;
    const vcsSocket = reactive(new SocketClient(baseUrl + 'ws-vcs', false));
    const analyticsSocket = reactive(
        new SocketClient(baseUrl + 'ws-app', false),
    );

    return {
        vcsSocket,
        analyticsSocket,
    };
}
