import { computed, reactive, ref } from 'vue';
import i18n from '@/i18n';
import { useUserStore } from '@/pinia/stores/user';
import { useRoomStore } from '@/pinia/stores/room';
import { useLayoutStore } from '@/pinia/stores/layout';
import { MAIN_TOOLBAR_BUTTONS, TOOLBAR_BUTTONS } from '@/constants/jitsi';
import analyticsIcon from '@/assets/icons/analytics.svg?raw';
import { useFaceDetector } from '@index5/face-detector/vue';

export function useJitsiApi() {
    const userStore = useUserStore();
    const roomStore = useRoomStore();
    const layoutStore = useLayoutStore();

    const jitsiApi = ref<any>();
    const jitsiDomain = computed(() => import.meta.env.VUE_APP_JITSI_DOMAIN);

    const { createAudioRecorder, startRecording, stopRecording } =
        useFaceDetector();

    const localParticipantInfo = reactive({
        id: '',
        displayName: '',
        isJoined: false,
        isCameraEnabled: false,
        isMicrophoneEnabled: false,
        isScreenShareEnabled: false,
    });

    const loadScript = () => {
        return new Promise((resolve) => {
            const scriptEl = document.createElement('script');
            scriptEl.src = `https://${jitsiDomain.value}/external_api.js`;
            scriptEl.async = true;

            document.querySelector('head')!.appendChild(scriptEl);

            scriptEl.addEventListener('load', resolve);
        });
    };

    const addEventListener = (event: string, fn: (...args: any) => void) => {
        jitsiApi.value?.on(event, fn);
    };

    const embedJitsiWidget = async (
        container: HTMLDivElement | null,
        onLoad?: () => void,
    ) => {
        await loadScript();

        if (!window.JitsiMeetExternalAPI) {
            throw new Error('Jitsi Meet External API not loaded');
        }

        const { t, locale } = i18n.global;

        jitsiApi.value = new window.JitsiMeetExternalAPI(jitsiDomain.value, {
            roomName: `${roomStore.meetingRoom?.id}/${roomStore.meetingRoom?.name}`,
            parentNode: container,
            userInfo: {
                displayName: userStore.profile?.nickname,
                email: userStore.profile?.email,
            },
            configOverwrite: {
                defaultLanguage: locale.value,
                disableInviteFunctions: true,
                toolbarButtons: TOOLBAR_BUTTONS,
                mainToolbarButtons: MAIN_TOOLBAR_BUTTONS,
                customToolbarButtons: [
                    {
                        id: 'analytics',
                        icon: `data:image/svg+xml;base64,${btoa(
                            analyticsIcon,
                        )}`,
                        text: t('toolbar-buttons.analytics'),
                    },
                ],
            },
            onload() {
                onLoad?.();
            },
        });

        const resetJitsiInfo = () => {
            localParticipantInfo.id = '';
            localParticipantInfo.displayName = '';
            localParticipantInfo.isJoined = false;
            localParticipantInfo.isCameraEnabled = false;
            localParticipantInfo.isMicrophoneEnabled = false;
            localParticipantInfo.isScreenShareEnabled = false;

            jitsiApi.value = undefined;
        };

        addEventListener('videoConferenceJoined', ({ displayName, id }) => {
            if (localParticipantInfo.isJoined) return;

            localParticipantInfo.isJoined = true;
            localParticipantInfo.id = id;
            localParticipantInfo.displayName = displayName;

            createAudioRecorder(
                'https://index5-back-prod.aoneiro.com/speech-recognition',
                id,
                roomStore.meetingRoom?.id || '',
                displayName,
            );

            Promise.all([roomStore.updateMembers(), roomStore.enterRoom()]);
        });

        addEventListener('readyToClose', () => {
            roomStore.handleRoomDisconnect();
            resetJitsiInfo();
            stopRecording();
        });

        addEventListener('audioMuteStatusChanged', ({ muted }) => {
            localParticipantInfo.isMicrophoneEnabled = !muted;
            if (!muted) {
                startRecording();
            } else {
                stopRecording();
            }
        });

        addEventListener('videoMuteStatusChanged', ({ muted }) => {
            localParticipantInfo.isCameraEnabled = !muted;
        });

        addEventListener('screenSharingStatusChanged', ({ on }) => {
            localParticipantInfo.isScreenShareEnabled = on;
        });

        addEventListener('toolbarButtonClicked', ({ key }) => {
            if (key === 'analytics') {
                layoutStore.toggleWidget('analytics');
            }
        });

        addEventListener('displayNameChange', roomStore.updateMembers);
        addEventListener('participantJoined', roomStore.updateMembers);
        addEventListener('participantLeft', roomStore.updateMembers);
        addEventListener('participantKickedOut', roomStore.updateMembers);
        addEventListener('participantRoleChanged', roomStore.updateMembers);
    };

    return {
        jitsiApi,
        localParticipantInfo,
        embedJitsiWidget,
    };
}
