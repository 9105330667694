import { setLocale } from 'yup';
import i18n from '@/i18n';

export const initValidator = () => {
    setLocale({
        string: {
            email: () => i18n.global.t('form.validation.email'),
            url: () => i18n.global.t('form.validation.url'),
            matches: () => i18n.global.t('form.validation.regex'),
            min: ({ min }) =>
                i18n.global.t('form.validation.min.string', { min }),
            max: ({ max }) =>
                i18n.global.t('form.validation.max.string', { max }),
        },
        number: {
            min: ({ min }) =>
                i18n.global.t('form.validation.min.numeric', { min }),
            max: ({ max }) =>
                i18n.global.t('form.validation.max.numeric', { max }),
        },
        mixed: {
            required: () => i18n.global.t('form.validation.required'),
        },
    });
};
