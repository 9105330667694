<template>
    <VTooltip
        v-model="modelValue"
        class="tooltip"
        content-class="tooltip__content"
        scroll-strategy="close"
        :offset="offsetDistance"
        :location="placement"
        :disabled="isDisabled"
        :interactive="false"
        arrow
        hover
    >
        <template #activator="{ props }">
            <div
                v-bind="props"
                :class="`tooltip__activator ${activatorClass}`"
                @click="modelValue = false"
            >
                <slot name="default" />
            </div>
        </template>

        <slot name="content">
            {{ text }}
        </slot>
    </VTooltip>
</template>

<script setup lang="ts">
import { ref, computed, useSlots } from 'vue';
import { VTooltip } from 'vuetify/components';

interface Props {
    text?: string;
    disabled?: boolean;
    offsetDistance?: string;
    placement?: VTooltip['location'];
    activatorClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
    placement: 'bottom',
    offsetDistance: '18',
    activatorClass: '',
    text: '',
});

const slots = useSlots();

const modelValue = ref(false);

const isDisabled = computed(
    () => props.disabled || (!props.text && !slots['content']),
);
</script>

<style lang="scss">
.v-tooltip > .tooltip__content {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    padding: 15px 14px;
    background-color: $black-gray;
    color: $white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);
}
</style>
