export enum Roles {
    LOCAL_ADMIN = 'LOCAL_ADMIN',
    MEMBER = 'MEMBER',
}

export type Department = {
    id: string;
    organizationId: string;
    name: string;
    color: string;
};

export type Member = {
    id: number;
    email: string;
    nickname: string;
    is_guest: boolean | null;
    avatar_url: boolean | null;
    isBusinessEntity: boolean;
    organizationId: string;
    organizationRole: string;
    organizationDepartmentId: string;
    organizationDepartment: Department;
};

type User = {
    id: string;
    organizationId: string;
    name: string;
    color: string;
};

export type InviteItem = {
    id: string;
    creatorId: number;
    organizationId: string;
    email: string;
    code: string;
    role: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
};

export interface CreateOrganizationResponse {
    name: string;
    ITN: string;
    address: string;
}

export interface CreateOrganizationPayload {
    name: string;
    ITN: string;
    address: string;
}

export interface Organization {
    members: Member[];
    organizationId: string;
    id: string;
    creatorId: number;
    name: string;
    ITN: string;
    address: string;
    createdAt: string;
    updatedAt: string;
    dashboardLinks: OrgDashboardLinks | null;
    creator: {
        id: number;
        email: string;
        nickname: string;
        is_guest: boolean | null;
        avatar_url: string | null;
        isBusinessEntity: string;
        organizationId: string;
        organizationRole: string;
    };
}

export interface GetUsersResponse {
    users: User[];
}

export interface GetOrganizationPayload {
    organizationId: string;
}

export interface ChangeOrganizationPayload {
    id: string;
    name: string;
    ITN: string;
    address: string;
}

export interface ChangeOrganizationResponse
    extends Omit<Organization, 'creator' | 'dashboardLinks' | 'members'> {
    creatorId: number;
}

export interface ChangeUserRoleOrganizationPayload {
    organizationId: string;
    userId: number;
    role: string;
}

export interface DeleteUserOrganizationPayload {
    organizationId: string;
    userId: number;
}

export interface InviteOrganizationPayload {
    organizationId: string;
    email: string;
    role: string;
}

export interface CheckInviteOrganizationPayload {
    organizationId: string;
    email: string;
    code: string;
}

export interface AcceptInviteOrganizationPayload {
    organizationId: string;
    inviteId: string;
}

export interface GetInviteListPayload {
    organizationId: string;
    skip: number;
    take: number;
}

export interface GetInvitesListResponse {
    success: boolean;
    data: InviteItem[];
}

export interface DeleteInvitePayload {
    inviteId: string;
    organizationId: string;
}

export interface GetUsersListPayload {
    organizationId: string;
}

export interface GetDepartmentsListPayload {
    organizationId: string;
}

export interface GetDepartmentsListResponse {
    success: boolean;
    data: Department[];
}

export interface AddDepartmentResponse {
    id: string;
    organizationId: string;
    name: string;
    color: string;
    createdAt: string;
    updatedAt: string;
}

export interface AddDepartmentPayload {
    organizationId: string;
    name: string;
    color: string;
}

export interface GetDepartmentPayload {
    departmentId: string;
    organizationId: string;
}

export interface GetDepartmentResponse {
    id: string;
    organizationId: string;
    name: string;
    color: string;
}

export interface UpdateDepartmentPayload {
    departmentId: string;
    organizationId: string;
    name: string;
    color: string;
}

export interface DeleteDepartmentPayload {
    departmentId: string;
    organizationId: string;
}

export interface DeleteDepartmentPayload {
    departmentId: string;
    organizationId: string;
}

export interface AddUserDepartmentPayload {
    organizationId: string;
    userId: number;
    departmentId: string;
}

export interface OrgDashboardLink {
    url: string;
}

export type OrgDashboardLinks = Partial<Record<'en' | 'ru', OrgDashboardLink>>;

export interface SetOrganizationDashboardsPayload {
    organizationId: string;
    links: OrgDashboardLinks | null;
}

export type CheckInviteResponse = {
    id: string;
    creatorId: number;
    organizationId: string;
    email: string;
    code: string;
    role: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
};
