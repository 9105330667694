import 'vuetify/styles';

import { createVuetify } from 'vuetify';
import { ru } from 'vuetify/locale';
import { aliases, fa } from 'vuetify/iconsets/fa';
import { mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { BREAKPOINTS } from '@/constants';

export const vuetify = createVuetify({
    components,
    directives,
    locale: {
        locale: 'ru',
        messages: { ru },
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: { fa, mdi },
    },
    theme: {
        defaultTheme: 'dark',
        themes: {
            dark: {
                dark: true,
                colors: {
                    background: '#18191d',
                },
            },
        },
    },
    display: {
        thresholds: BREAKPOINTS,
    },
});
