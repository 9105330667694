<template>
    <span
        :class="classes"
        :style="{
            transform: rotate ? `rotate(${rotate}deg)` : undefined,
            fontSize: size ? `${size}px` : undefined,
        }"
    >
        <component
            :is="icon"
            :role="interactive ? 'button' : null"
            :tabindex="interactive ? 0 : null"
        />
    </span>
</template>

<script setup lang="ts">
import { computed, shallowRef, watch } from 'vue';

interface Props {
    name: string;
    size?: string | number;
    interactive?: boolean;
    rotate?: number | string;
    spin?: boolean;
}

const props = defineProps<Props>();

const icon = shallowRef();

const classes = computed(() => [
    'ui-icon',
    {
        'ui-icon--interactive': props.interactive,
        'ui-icon--spin': props.spin,
    },
]);

watch(
    () => props.name,
    (name) => {
        // TODO: use path alias
        import(`../../assets/ui-icons/${name}.svg`).then(
            (value) => (icon.value = value.default),
        );
    },
    { immediate: true },
);
</script>

<style lang="scss">
.ui-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;

    &--interactive {
        cursor: pointer;
    }

    &--spin {
        animation: spin 1s infinite linear;
    }

    svg {
        fill: none;
        width: 1em;
        height: 1em;
    }
}
</style>
