/**
 * Base meeting roles described on
 * https://docs.google.com/spreadsheets/d/1uCtUgbO9CI1UR2SF7ZhC62CCyrgoSP8KZ-JGolWVxEM/edit#gid=0
 */
export const MeetingUserRole = {
    Organizer: 'Organizer',
    CoOrganizer: 'CoOrganizer',
    RegisteredMember: 'RegisteredMember',
    UnregisteredMember: 'UnregisteredMember',
} as const;

export const allRoles = [
    MeetingUserRole.Organizer,
    MeetingUserRole.CoOrganizer,
    MeetingUserRole.RegisteredMember,
    MeetingUserRole.UnregisteredMember,
];

export enum MeetingActions {
    /** Global */
    /* Завершить встречу */
    GlobalEndMeeting = 'GlobalEndMeeting',

    /** Broadcasting */
    /* Запуск трансляции */
    BroadcastStart = 'BroadcastStart',
    /* Редактирование трансляции  */
    BroadcastEdit = 'BroadcastEdit',
    /* Удаления трансляции  */
    BroadcastDelete = 'BroadcastDelete',
    /* Просмотр */
    BroadcastView = 'BroadcastView',

    /** Sound */
    /* Включение/отключение своего звука */
    SoundOwnTurn = 'SoundOwnTurn',
    /* Включение/отключение звука других участников */
    SoundOtherTurn = 'SoundOtherTurn',
    /* Редактирование звука для встречи */
    SoundMeetingSettings = 'SoundMeetingSettings',
    /* Общее отключение звука всем участникам */
    SoundGroupDisable = 'SoundGroupDisable',
    /* Настройка своего звука на экране лобби  */
    SoundSettingsOnLobbyScreen = 'SoundSettingsOnLobbyScreen',
    /* Настройка своего звука на встрече  */
    SoundSettingsOnMeeting = 'SoundSettingsOnMeeting',
    /* Удаление звука во всей встрече */
    SoundDeleteForMeeting = 'SoundDeleteForMeeting',

    /** Video */
    /* Запуск/остановка своего видео на встрече */
    VideoOwnTurn = 'VideoOwnTurn',
    /* Запуск/остановка видео других участников */
    VideoOtherTurn = 'VideoOtherTurn',
    /* Редактирование видео для встречи */
    VideoMeetingSettings = 'VideoMeetingSettings',
    /* Общее отключение видео всем участникам */
    VideoGroupDisable = 'VideoGroupDisable',
    /* Просить участника группы запустить видео */
    VideoAskMemberEnableVideo = 'VideoAskMemberEnableVideo',
    /* Удаление видео во всей встрече */
    VideoDeleteForMeeting = 'VideoDeleteForMeeting',
    /* Просмотр */
    VideoView = 'VideoView',
    /* Настройка своего видео на встрече */
    VideoSettingsOnMeeting = 'VideoSettingsOnMeeting',
    /* Настройка своего видео на экране лобби */
    VideoSettingsOnLobbyScreen = 'VideoSettingsOnLobbyScreen',

    /** Screen sharing */
    /* Запуск/остановка своей демонстрации на встрече */
    ScreenSharingOwnTurn = 'ScreenSharingOwnTurn',
    /* Запуск/остановка демонстрации  других участников */
    ScreenSharingOtherTurn = 'ScreenSharingOtherTurn',
    /* Настройки/редактирования демонстрации во время встречи */
    ScreenSharingSettingsOnMeeting = 'ScreenSharingSettingsOnMeeting',
    /* Настройки/редактирования демонстрации в личном кабинете  */
    ScreenSharingSettingsOnPersonalArea = 'ScreenSharingSettingsOnPersonalArea',
    /* Просмотр */
    ScreenSharingView = 'ScreenSharingView',

    /** Chat */
    /* Запуск/Закрытие чата */
    ChatOpenClose = 'ChatOpenClose',
    /* Редактирование всего чата  */
    ChatGlobalEdit = 'ChatGlobalEdit',
    /* Просмотр чата  */
    ChatView = 'ChatView',
    /* Скрытие чата */
    ChatHide = 'ChatHide',
    /* Сохранять чат */
    ChatSave = 'ChatSave',
    /* Удалять чат */
    ChatDelete = 'ChatDelete',
    /* Удалять свои сообщения в чате  */
    ChatOwnMessageDelete = 'ChatOwnMessageDelete',
    /* Редактировать свои сообщения в чате */
    ChatOwnMessageEdit = 'ChatOwnMessageEdit',
    /* Использовать приватные сообщения  */
    ChatUsingPrivateMessages = 'ChatUsingPrivateMessages',
    /* Добавлять файлы и картинки в чат */
    ChatAttachMedia = 'ChatAttachMedia',
    /* Управление возможностями общения присутствующих в чате */
    ChatManagingFeatures = 'ChatManagingFeatures',
    /* Удалять конкретные сообщения участников в чате */
    ChatGlobalMessageDelete = 'ChatGlobalMessageDelete',
    /* Заблокировать чат */
    ChatBlock = 'ChatBlock',

    /** Questionnaire */
    /* Запуск/Закрытие Опросника */
    QuestionnaireOpenClose = 'QuestionnaireOpenClose',
    /* Задавать вопросы в разделе «Вопрос-ответ» */
    QuestionnaireAskInQASection = 'QuestionnaireAskInQASection',
    /* Просматривать все содержимое раздела «Вопрос-ответ» и добавлять ответы, изменение опросов */
    QuestionnaireQASectionViewAll = 'QuestionnaireQASectionViewAll',
    /* Запуск опроса */
    QuestionnaireStart = 'QuestionnaireStart',
    /* Просмотр  */
    QuestionnaireView = 'QuestionnaireView',
    /* Удаление опроса */
    QuestionnaireDelete = 'QuestionnaireDelete',
    /* Участие в опросах */
    QuestionnaireParticipation = 'QuestionnaireParticipation',

    /** Recordings */
    /* Запуск/Закрытие записи */
    RecordingsOpenClose = 'RecordingsOpenClose',
    /* Запись встречи */
    RecordingStart = 'RecordingStart',
    /* Сохранять запись встречи */
    RecordingSave = 'RecordingSave',
    /* Удалять запись встречи */
    RecordingDelete = 'RecordingDelete',
    /* Остановка записи */
    RecordingStop = 'RecordingStop',
    /* Просмотр записи */
    RecordingView = 'RecordingView',
    /* Выгрузка записи */
    RecordingUpload = 'RecordingUpload',

    /** Camera inverting */
    /* Запуск/Закрытие инверсии */
    CameraInvertingOpenClose = 'CameraInvertingOpenClose',
    /* Отключение / заблокировать */
    CameraInvertingDisableAndBlock = 'CameraInvertingDisableAndBlock',
    /* Просмотр */
    CameraInvertingView = 'CameraInvertingView',

    /** Hand rise */
    /* Запуск/Закрытие Поднятие руки */
    HandRiseOpenClose = 'HandRiseOpenClose',
    /* Редактирование Поднятие руки */
    HandRiseEdit = 'HandRiseEdit',
    /* Отключение / заблокировать */
    HandRiseDisableAndBlock = 'HandRiseDisableAndBlock',
    /* Просмотр */
    HandRiseView = 'HandRiseView',

    /** Reactions */
    /* Запуск/Закрытие Реакции */
    ReactionsOpenClose = 'ReactionsOpenClose',
    /* Редактирование Реакции */
    ReactionsEdit = 'ReactionsEdit',
    /* Просмотр */
    ReactionsView = 'ReactionsView',
    /* Отключение / заблокировать */
    ReactionsDisableAndBlock = 'ReactionsDisableAndBlock',

    /** Interactive board */
    /* Запуск/Закрытие Доски */
    IntBoardOpenClose = 'IntBoardOpenClose',
    /* Запуск интерактивной доски и презентация */
    IntBoardStartPresentation = 'IntBoardStartPresentation',
    /* Запуск интерактивной доски в режиме коллективного использования */
    IntBoardStartGroupMode = 'IntBoardStartGroupMode',
    /* Подключаться к коллективной доске  */
    IntBoardJoinGroupMode = 'IntBoardJoinGroupMode',
    /* Сохранение доски */
    IntBoardSave = 'IntBoardSave',
    /* Удаление доски */
    IntBoardDelete = 'IntBoardDelete',
    /* Редактирование доски */
    IntBoardEditing = 'IntBoardEditing',

    /** Effects */
    /* Запуск/Закрытие Эффекта */
    EffectsOpenClose = 'EffectsOpenClose',
    /* Просмотр */
    EffectsView = 'EffectsView',
    /* Редактирование/выбор эффекта во время встречи */
    EffectsSelectAndEdit = 'EffectsSelectAndEdit',
    /* Настройка эффекта в лк */
    EffectsSettingsInPersonalArea = 'EffectsSettingsInPersonalArea',
    /* Добавление эффекта в коллекцию */
    EffectsSaveInCollection = 'EffectsSaveInCollection',

    /** Speech to Text */
    /* Запуск/Закрытие Эффекта */
    SpeechToTextOpenClose = 'SpeechToTextOpenClose',
    /* Просмотр */
    SpeechToTextView = 'SpeechToTextView',
    /* Принудительное включение перевода речи в текст для всех */
    SpeechToTextForceEnableForAll = 'SpeechToTextForceEnableForAll',
    /* Сохранение текста  */
    SpeechToTextSaveText = 'SpeechToTextSaveText',
    /* Заблокировать для всех  */
    SpeechToTextBlockForAll = 'SpeechToTextBlockForAll',
    /* Настройка по локализации */
    SpeechToTextLocalizationSetting = 'SpeechToTextLocalizationSetting',
    /* Настройка как субтитры */
    SpeechToTextSubtitlesSetting = 'SpeechToTextSubtitlesSetting',

    /** Gallery modes */
    /* Запуск/Закрытие Режимов */
    GalleryModesOpenClose = 'GalleryModesOpenClose',
    /* Просмотр */
    GalleryModesView = 'GalleryModesView',
    /* Полноэкранный режим */
    GalleryModesFullScreen = 'GalleryModesFullScreen',
    /* Переключение списка плиток */
    GalleryModesSwitchTilesList = 'GalleryModesSwitchTilesList',
    /* Отключение Полноэкранного режима */
    GalleryModesFullScreenDisable = 'GalleryModesFullScreenDisable',
    /* Группировка плиток */
    GalleryModesTilesGrouping = 'GalleryModesTilesGrouping',
    /* Принудительные настройки плиток для встречи */
    GalleryModesTilesSettingsForMeeting = 'GalleryModesTilesSettingsForMeeting',
    /* Принудительные настройки плиток для всех участников */
    GalleryModesForceTilesSettingsForMembers = 'GalleryModesForceTilesSettingsForMembers',

    /** Analytics */
    /* Запуск/Закрытие Аналитики */
    AnalyticsOpenClose = 'AnalyticsOpenClose',
    /* Просмотр  */
    AnalyticsView = 'AnalyticsView',
    /* Скрыть Аналитику от участников */
    AnalyticsHideForMembers = 'AnalyticsHideForMembers',
    /* Скачивания Аналитики */
    AnalyticsDownload = 'AnalyticsDownload',

    /** Members list */
    /* Запуск/Закрытие Участники */
    MembersListOpenClose = 'MembersListOpenClose',
    /* Просмотр списка присутствующих  */
    MembersListView = 'MembersListView',
    /* Выключать показатели индексов у всех участников во время встречи */
    MembersListDisableIndexes = 'MembersListDisableIndexes',
    /* Повышение статуса присутствующего до Соорганизатора */
    MembersListIncreaseMemberRole = 'MembersListIncreaseMemberRole',
    /* Понижение статуса участника группы до присутствующего */
    MembersListDecreaseMemberRole = 'MembersListDecreaseMemberRole',
    /* Изменение представления присутствующего */
    MembersListChangeMemberPresentation = 'MembersListChangeMemberPresentation',
    /* Изменения представления своего нейма */
    MembersListChangeOwnName = 'MembersListChangeOwnName',
    /* Поле поиск */
    MembersListSearchField = 'MembersListSearchField',
    /* Удаление присутствующих */
    MembersListDeleteMember = 'MembersListDeleteMember',
    /* Перевод участника группы в режим ожидания */
    MembersListStandbyMember = 'MembersListStandbyMember',
    /* Запрос быть спикером (соорганизатором) */
    MembersListRequestIncreaseOwnRole = 'MembersListRequestIncreaseOwnRole',
    /* Приглашать других пользователей присоединиться к вебинару */
    MembersListInvitation = 'MembersListInvitation',
    /* Помощь */
    MembersListHelp = 'MembersListHelp',

    /** Sessions rooms */
    /* Запуск/Закрытие Комнаты */
    SessionsRoomsOpenClose = 'SessionsRoomsOpenClose',
    /* Просмотр */
    SessionsRoomsView = 'SessionsRoomsView',
    /* Редактирование комнат */
    SessionsRoomsEdit = 'SessionsRoomsEdit',
    /* Удаление комнат */
    SessionsRoomsDelete = 'SessionsRoomsDelete',
    /* Создание комнат */
    SessionsRoomsCreate = 'SessionsRoomsCreate',
}
