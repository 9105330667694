import api, { handleApiError } from '@/api';
import { ApiResponse } from '../types';
import { GetPermissionsResponse, UpdateUserRolePayload } from './types';

export const getPermissions = async (
    meetingId: string,
): ApiResponse<GetPermissionsResponse> => {
    try {
        const { data } = await api.get<GetPermissionsResponse>(
            'access/meeting/permissions',
            { params: { meetingId } },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const updateUserRole = async (
    payload: UpdateUserRolePayload,
): ApiResponse => {
    try {
        const { data } = await api.post('access/meeting/role', payload);

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};
