export const MAIN_TOOLBAR_BUTTONS = [
    [
        'microphone',
        'camera',
        'desktop',
        'chat',
        'raisehand',
        'reactions',
        'participants-pane',
        'analytics',
    ],
    [
        'microphone',
        'camera',
        'desktop',
        'chat',
        'raisehand',
        'participants-pane',
        'analytics',
    ],
    [
        'microphone',
        'camera',
        'desktop',
        'chat',
        'participants-pane',
        'analytics',
    ],
    ['microphone', 'camera', 'desktop', 'chat', 'analytics'],
    ['microphone', 'camera', 'chat', 'analytics'],
    ['microphone', 'camera', 'chat'],
    ['microphone', 'camera'],
];

export const TOOLBAR_BUTTONS = [
    'camera',
    'chat',
    'closedcaptions',
    'desktop',
    'download',
    'embedmeeting',
    'etherpad',
    'feedback',
    'filmstrip',
    'fullscreen',
    'hangup',
    'help',
    'highlight',
    'linktosalesforce',
    'livestreaming',
    'microphone',
    'noisesuppression',
    'participants-pane',
    'profile',
    'raisehand',
    'recording',
    'security',
    'select-background',
    'settings',
    'shareaudio',
    'sharedvideo',
    'shortcuts',
    'stats',
    'tileview',
    'toggle-camera',
    'videoquality',
    'whiteboard',
];
