/* eslint-disable no-console */

const isEnabled = import.meta.env.VUE_APP_LOGGER_ENABLED;

const styles = (bg: string) =>
    [
        'color: white;',
        'padding: 4px;',
        'border-radius: 5px;',
        `background-color: ${bg};`,
    ].join(';');

export function consoleLog(label: string, ...message: any[]) {
    if (isEnabled) console.log(`%c${label}`, styles('#1871f8'), ...message);
}

export function consoleWarn(label: string, ...message: any[]) {
    console.warn(`%c${label}`, styles('#eeab3a'), ...message);
}

export function consoleError(label: string, ...message: any[]) {
    console.error(`%c${label}`, styles('#fa5555'), ...message);
}

export default function createLogger(label: string) {
    return {
        log: (...message: any[]) => consoleLog(label, ...message),
        warn: (...message: any[]) => consoleWarn(label, ...message),
        error: (...message: any[]) => consoleError(label, ...message),
    };
}
