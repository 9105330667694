<template>
    <span
        class="svg-сolored"
        :style="svgColoredStyles"
    />
</template>

<script lang="ts" setup>
import { SvgSize, getFormattedSize } from '@/utils/common';
import { computed, PropType } from 'vue';

///// props /////

const props = defineProps({
    path: {
        type: String,
        default: '',
    },
    size: {
        type: [Number, String] as PropType<SvgSize>,
        default: 20,
    },
    color: {
        type: String,
        default: 'red',
    },
});

///// computed from component /////

const svgColoredStyles = computed(() => {
    const formattedSize = getFormattedSize(props.size);

    return {
        maskImage: `url(${props.path})`,
        WebkitMaskImage: `url(${props.path})`,
        '--svgColoredSize': formattedSize,
        backgroundColor: props.color,
    };
});
</script>

<style scoped>
.svg-сolored {
    display: block;
    width: var(--svgColoredSize);
    height: var(--svgColoredSize);
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
}
</style>
