import { defineStore, acceptHMRUpdate } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import i18n from '@/i18n';
import { createLogger, redirect } from '@/utils';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import {
    login as authLogin,
    register as authRegister,
    refreshUserTokens,
} from '@/api/authorization';
import type { LoginPayload, RegisterPayload } from '@/api/authorization/types';

export const useAuthStore = defineStore('auth', () => {
    const logger = createLogger(`🍍auth`);

    const accessToken = useLocalStorage<string>(
        LOCAL_STORAGE_KEYS['accessToken'],
        '',
    );
    const refreshToken = useLocalStorage<string>(
        LOCAL_STORAGE_KEYS['refreshToken'],
        '',
    );

    function setTokens(access: string, refresh: string) {
        accessToken.value = access;
        refreshToken.value = refresh;
    }

    function removeTokens() {
        accessToken.value = null;
        refreshToken.value = null;
    }

    async function refreshTokens() {
        const response = await refreshUserTokens(refreshToken.value);

        if (!response.success) {
            logger.error(`refreshTokens | ${response.message}`);
            logout();
            return response;
        }

        setTokens(response.data.access_token, response.data.refresh_token);

        return response;
    }

    async function register(payload: RegisterPayload) {
        const response = await authRegister(payload);

        if (!response.success) {
            logger.error(response.message);
            return response;
        }

        setTokens(response.data.access_token, response.data.refresh_token);

        return {
            message: i18n.global.t('register.message.success'),
            success: true,
        };
    }

    async function login(payload: LoginPayload) {
        const response = await authLogin(payload);

        if (!response.success) {
            localStorage.setItem('timeLeft', response.timeLeft);

            if (response.statusCode === 403) {
                localStorage.setItem('isVerified', 'false');
            }

            logger.error(response.message);

            return response;
        }

        setTokens(response.data.access_token, response.data.refresh_token);

        localStorage.setItem('is_2FA_active', response.data.is_2FA_active);

        if (accessToken.value) {
            redirect({ name: 'MeetingsPage' });
        }

        return response;
    }

    function logout() {
        localStorage.removeItem('is_2FA_active');
        removeTokens();
        window.location.reload();
    }

    return {
        accessToken,
        refreshToken,
        register,
        login,
        logout,
        removeTokens,
        refreshTokens,
        setTokens,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
