import api, { handleApiError } from '@/api';
import { ApiResponse } from '../types';
import {
    CreateUserPayload,
    UpdateUserPayload,
    User,
    PaginatedUsers,
    UpdateUserPasswordPayload,
} from './types';

export const getCurrentUser = async (): ApiResponse<User> => {
    try {
        const { data } = await api.get<User>('users/me');

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getUserById = async (id: string | number): ApiResponse<User> => {
    try {
        const { data } = await api.get<User>('users', {
            params: { id },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getPaginatedUsers = async (
    page = 1,
    size = 10,
): ApiResponse<PaginatedUsers> => {
    try {
        const { data } = await api.get<PaginatedUsers>('users', {
            params: { page, size },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const createUser = async (
    payload: CreateUserPayload,
): ApiResponse<User> => {
    try {
        const { data } = await api.post<User>('users', payload);

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const updateUser = async (
    payload: UpdateUserPayload,
): ApiResponse<User> => {
    try {
        const { data } = await api.patch<User>('users', payload);

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const updateUserPassword = async (
    userId: number,
    payload: UpdateUserPasswordPayload,
): ApiResponse => {
    try {
        const { data } = await api.patch(`users/${userId}/password`, payload);

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const deleteUser = async (id: number): ApiResponse => {
    try {
        const { data } = await api.delete('users', {
            params: { id },
        });

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const uploadAvatar = async (avatar: FormData): ApiResponse => {
    try {
        const { data } = await api.post('users/upload', avatar);

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};
