import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faMicrophone,
    faUser,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faDesktop,
    faPhoneSlash,
    faChartColumn,
    faMessage,
    faUsers,
    faTimes,
    faPaperPlane,
    faSearch,
    faUserPlus,
    faEllipsisH,
    faCopy,
    faFileDownload,
    faTrash,
    faPlay,
    faCheck,
    faRightFromBracket,
    faXmark,
    faVolumeUp,
    faAngleDown,
    faCalendar,
    faRotate,
    faEye,
    faEyeSlash,
    faAngleRight,
    faAngleLeft,
    faGear,
    faCheckCircle,
    faTimesCircle,
    faUserAlt,
    faCompress,
    faChevronUp,
    faChevronDown,
    faRotateLeft,
    faRotateRight,
    faChevronLeft,
    faChevronRight,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faExpand,
    faEraser,
    faImage,
    faPlus,
    faComment,
    faCommentSlash,
    faPaperclip,
    faFile,
    faChalkboard,
    faSmile,
    faFont,
    faCamera,
    faClosedCaptioning,
    faEnvelope,
    faDownload,
    faMagnifyingGlass,
    faPowerOff,
    faMicrochip,
    faSquarePollVertical,
    faSquarePollHorizontal,
    faCircleNotch,
    faCircle,
    faSortDown,
    faSmileBeam,
    faFaceSmile,
    faFaceLaugh,
    faFaceFrown,
    faAddressCard,
    faPenToSquare,
    faTag,
    faStar,
    faSliders,
    faSync,
    faChartPie,
    faLock,
    faUserShield,
    faWifi,
    faUserTimes,
    faEllipsisVertical,
    faUpRightAndDownLeftFromCenter,
    faDownLeftAndUpRightToCenter,
    faThumbTack,
    faQuestionCircle,
    faMicrophoneLines,
    faUserCheck,
    faUserSlash,
    faUserClock,
    faSignOutAlt,
    faCalendarDays,
    faFileText,
    faFileVideo,
    faFilePdf,
    faClockRotateLeft,
    faLink,
    faHand,
    faPen,
    faTriangleExclamation,
    faArrowRightFromBracket,
    faFileCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCalendar as farCalendar,
    faCircle as farCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faUser,
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faDesktop,
    faPhoneSlash,
    faChartColumn,
    faMessage,
    faUsers,
    faTimes,
    faPaperPlane,
    faSearch,
    faUserPlus,
    faEllipsisH,
    faEllipsisVertical,
    faCopy,
    faFileDownload,
    faPen,
    faTrash,
    faPlay,
    faCheck,
    faRightFromBracket,
    faAngleDown,
    faAngleRight,
    faAngleLeft,
    faVolumeUp,
    faXmark,
    farCalendar,
    faCalendar,
    faRotate,
    faEye,
    faEyeSlash,
    faGear,
    faCheckCircle,
    faTimesCircle,
    faUserAlt,
    faCompress,
    faChevronUp,
    faChevronDown,
    faRotateLeft,
    faRotateRight,
    faChevronLeft,
    faChevronRight,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faMagnifyingGlass,
    faExpand,
    faEraser,
    faImage,
    faPlus,
    faComment,
    faCommentSlash,
    faPaperclip,
    faFile,
    faChalkboard,
    faSmile,
    faFont,
    faCamera,
    faClosedCaptioning,
    faEnvelope,
    faDownload,
    faPowerOff,
    faMicrochip,
    faSquarePollVertical,
    faSquarePollHorizontal,
    faCircleNotch,
    faCircle,
    faSortDown,
    faSmileBeam,
    faFaceSmile,
    faFaceLaugh,
    faFaceFrown,
    faAddressCard,
    faPenToSquare,
    faTag,
    faStar,
    faSliders,
    farCircle,
    faSync,
    faChartPie,
    faLock,
    faUserShield,
    faWifi,
    faUserTimes,
    faUpRightAndDownLeftFromCenter,
    faDownLeftAndUpRightToCenter,
    faThumbTack,
    faEllipsisVertical,
    faUpRightAndDownLeftFromCenter,
    faDownLeftAndUpRightToCenter,
    faQuestionCircle,
    faMicrophoneLines,
    faUserCheck,
    faUserSlash,
    faUserClock,
    faSignOutAlt,
    faCalendarDays,
    faFileText,
    faFileVideo,
    faFilePdf,
    faClockRotateLeft,
    faLink,
    faHand,
    faTriangleExclamation,
    faArrowRightFromBracket,
    faFileCircleCheck,
);
