import { MemberRole } from '@index5/face-detector';

export interface WsUserInterface {
    id: number;
    nickname: string;
    avatar_url: string;
}

export interface BackendWsRoomClient {
    user: WsUserInterface;
    streamId: string;
    createdAt: string;
    updatedAt: string;
    isBoardDraw: boolean;
    laserState?: string;
    role: MemberRole;
    isChatBlocked: boolean;
    status: LobbyStatus;
}

export interface WebinarRoom {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    start_date_time: Date;
    name: string;
    ended_at?: Date;
    creator_name: string;
    creator_id: number;
    isPrivate: boolean;
}

export interface UpdateMessage {
    id: number;
    creatorId: number;
    text: string;
}

export interface DeleteMessage {
    id: number;
    creatorId: number;
}

export interface IUsersMeetingPayload {
    id: number;
    user_id: number;
    meeting_id: string;
    nickname: string;
    stream_id?: string;
    is_board_draw: boolean;
    status: LobbyStatus;
    laser_state: string;
    devices?: { audio: boolean; video: boolean };
    avatar_url?: string;
    reaction?: Reaction;
    handRaised?: boolean;
    createdAt: string;
    updatedAt: string;
    role: MemberRole;
    can_not_write?: boolean;
}

export interface LobbyPayload {
    meeting_id: string;
    video: boolean;
    audio: boolean;
    token: string;
    waitingMeetingMembers: IUsersMeetingPayload[];
    rejectedMeetingMembers: IUsersMeetingPayload[];
    approvedMeetingMembers: IUsersMeetingPayload[];
}

export enum NotificationType {
    success = 'success',
    error = 'error',
}

export enum LobbyStatus {
    approved = 'approved',
    waiting = 'waiting',
    rejected = 'rejected',
}

export enum Reaction {
    Fire,
    Like,
    Meh,
    Dislike,
    Ice,
}

export interface WidgetButton {
    tooltip: string;
    icon: string | string[];
    action(): void;
}

interface TranscriptPayloadFinal {
    time: number;
    isFinal: true;
}

interface TranscriptPayloadPartial {
    isFinal: false;
}

export type TranscriptPayload = (
    | TranscriptPayloadFinal
    | TranscriptPayloadPartial
) & {
    text: string;
    timestamp: number;
    language: string;
};

export interface BaseNotification {
    nickname: string;
    action: string;
    id: string;
    type?: NotificationType;
    icon?: string[];
    image?: { alt: string; src: string };
}

export interface RequestNotification {
    type: 'role' | 'camera' | 'microphone' | 'join';
    memberId: number;
    nickname: string;
}

export interface UpdateRolePayload {
    role: MemberRole;
    memberId: number;
    nickname: string;
}
